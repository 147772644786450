<template>
  <div>
    <!-- ADD Work Type FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add Payment Mode">
          <form>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="vx-col md:w-3/4 mx-auto">
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Payment Mode Name*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Payment Mode Name"
                        v-model="form.payment_mode_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Payment Mode Name')"
                        >{{ errors.first("Payment Mode Name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right">
                    <h6 class="mb-0">Logo*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vue-dropzone
                      @vdropzone-files-added="vdropzoneFilesAdded"
                      @vdropzone-success="vdropzoneSuccess"
                      ref="myVueDropzone"
                      id="dropzone"
                      :options="dropzoneOptions"
                    ></vue-dropzone>
                    <vs-button
                      v-if="imageUpload.deleteButtonStatus"
                      type="filled"
                      @click.prevent="clearImage()"
                      class="feather icon-trash mt-2"
                    ></vs-button>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right">
                    <h6 class="mb-0">Status*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-switch v-model="switch1" />
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Submit</vs-button
                >

                <vs-button
                  color="warning"
                  type="border"
                  class
                  @click.prevent="clearForm"
                  >Reset</vs-button
                >
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import MasterService from "@/services/MasterService.js";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import constant from "@/helper/constant";

const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import { BASEURL } from "@/config/index.js";
export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      excelurl: BASEURL(),
      submitStatus: true,
      switch1: true,
      designation_description: "",
      form: {
        payment_mode_name: "",
        payment_mode_icon: "",
        payment_mode_isActive: 1,
      },
      notification_days: 0,
      value: [],
      companies: [],
      User: "Select User",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      dropzoneOptions: {
        url:
          BASEURL() +
          "/v2/multipleUploads?organization_id=" +
          this.organization_id,
        acceptedFiles: "image/*",
        ...constant.DEFAULT_IMAGE,
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
      },
      imageUpload: {
        image_url: "",
        deleteButtonStatus: false,
      },
      contacts: contacts,
    };
  },
  beforeMount() {
    this.dropzoneOptions["url"] =
      BASEURL() + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptions["headers"]["authorization"] = this.access_token;
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    form: {
      handler: function (newVal, oldVal) {
        delete this.form["payment_mode_icon"];
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }

        if (!this.form.payment_mode_icon) {
          this.form["payment_mode_icon"] = "";
        }
      },
      deep: true,
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    onClickSampleDownload: function (URL) {
      window.open(URL, "_blank");
    },
    clearImage: function () {
      this.$refs.myVueDropzone.removeAllFiles();
      this.imageUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzone.enable();
      this.form.payment_mode_icon = "";
    },
    vdropzoneFilesAdded: function () {},
    vdropzoneSuccess: function (file, response) {
      this.form.payment_mode_icon = response.data.image_path;
      this.imageUpload.deleteButtonStatus = true;
      this.$refs.myVueDropzone.disable();
    },
    vdropzoneError: function (file, message, xhr) {},

    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.submitStatus = false;
          let payload = {
            payment_mode_id: "",
            payment_mode_name: this.form.payment_mode_name,
            payment_mode_icon: this.form.payment_mode_icon,
            payment_mode_isActive: this.switch1 ? "1" : "2",
          };

          MasterService.addPaymentMode(payload)
            .then((response) => {
              this.submitStatus = false;
              const { data } = response;
              this.$vs.loading.close();
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Created!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.clearForm();
                setTimeout(() => {
                  eventBus.$emit("conrefreshTablePaymentModeType", data);
                }, 1);
              } else {
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.submitStatus = true;
              this.$vs.notify({
                title: "Error!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            });
        }
      });
    },
    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_isactive/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.designation_description = "";
      this.notification_days = 0;
      this.value = [];
      this.$validator.reset();
      this.clearImage();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
};
</script>
